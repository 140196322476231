import React, { useState } from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './css/FAQ.css';

const FAQ = () => {
    const [activeKey, setActiveKey] = useState(null);
    const [visibleCount, setVisibleCount] = useState(5);

    const toggleFAQ = (key) => {
        setActiveKey(activeKey === key ? null : key);
    };

    const showMoreFAQs = () => {
        setVisibleCount(visibleCount + 6);
    };

    const faqs = [
        {
            key: '0',
            question: 'Which drones are compatible with your software?',
            answer: 'Currently, our software is compatible with the DJI Tello drone. This educational drone is ideal for individual enthusiasts and students to learn the basics of drone operation and coding. We are working on expanding compatibility to include more drones in the future. For custom drone integration, please contact us. We offer enterprise-level solutions and can integrate our software with your custom drones.'
        },
        {
            key: '1',
            question: 'Is the software free to use?',
            answer: 'Yes, our software is free to download and use for basic drone flying. However, to access advanced AI and cinematography features, a Pro subscription is required.'
        },
        {
            key: '2',
            question: 'Can your software be integrated with custom drones?',
            answer: 'Yes, we strive to make our software compatible with a wide range of drones. We also offer bespoke integrations for custom drones. Please contact us for more information on how we can work with your drone\'s API to integrate it with our software.'
        },
        {
            key: '3',
            question: 'Is the subscription refundable?',
            answer: 'We encourage you to download and test our software using the free version to ensure it meets your needs before committing to a subscription. This way, you can explore all the features and capabilities at no cost and make an informed decision when you\'re ready to upgrade. Thank you for understanding and choosing our product!'
        },
        {
            key: '4',
            question: 'How do I get started with the AI Drone Software?',
            answer: 'To get started, sign up and simply download and install the software from our website. Follow the setup instructions provided in the user manual or online tutorial to connect your drone and begin flying. If you need further assistance, our support team is here to help.'
        },
        {
            key: '5',
            question: 'What features are included in the Pro subscription?',
            answer: 'The Pro subscription includes advanced AI capabilities such as object detection, automated flight modes, cinematic filming techniques, and enhanced data analytics. It also offers priority support and access to new features as they are released.'
        },
        {
            key: '6',
            question: 'What kind of support do you offer?',
            answer: 'We offer a range of support options including email support, live chat, and a community forum. Pro subscribers receive priority support with faster response times and access to exclusive troubleshooting resources.'
        },
        {
            key: '7',
            question: 'What are the system requirements for the software?',
            answer: 'The software requires a Windows or macOS operating system, a modern multi-core processor, at least 8GB of RAM, and a stable internet connection for updates and some features. Detailed system requirements can be found on our website.'
        },
        {
            key: '8',
            question: 'Is the software compatible with mobile devices?',
            answer: 'Currently, our software is primarily designed for desktop and laptop computers. We are working on a mobile version to provide greater flexibility and convenience for our users.'
        },
        {
            key: '9',
            question: 'How do I update the software?',
            answer: 'Updating the software is simple. When an update is available, you will receive a notification within the software. Follow the on-screen instructions to download and install the latest version.'
        },
        {
            key: '10',
            question: 'Why does Mac OS warn me to move the app to Trash?',
            answer: (
                <>
                    We are currently in the process of getting the app signed with Apple Developers to provide a smoother experience. macOS displays this warning for unsigned apps to protect you from potentially malicious software. Rest assured, Tensor Pilot is safe to use. Thank you for your understanding and patience!
                    <br />
                    <br />
                    Please follow these steps to open the app for the first time:
                    <ol>
                        <li>When you first open the app, macOS will display a warning that the app is from an unidentified developer and suggest you move it to the Trash.</li>
                        <li>Click "Cancel" to dismiss this warning.</li>
                        <li>Right-click (or control-click) the app icon and select "Open" from the context menu.</li>
                        <li>In the new prompt that appears, click "Open" to confirm. The app will launch successfully.</li>
                        <li>This process is only required the first time you open the app.</li>
                    </ol>
                </>
            ),
        },
    ];

    return (
        <div className="faq-container mt-5">
            <h3 className='text-center mb-4'>
                FAQ
            </h3>
            <Accordion activeKey={activeKey}>
                {faqs.slice(0, visibleCount).map(faq => (
                    <Card key={faq.key} className="faq-item">
                        <Card.Header onClick={() => toggleFAQ(faq.key)} className="faq-header">
                            <Accordion.Toggle as={Button} variant="" className="faq-button">
                                <div className="faq-content">
                                    <h5 className="faq-question">{faq.question}</h5>
                                    <FontAwesomeIcon 
                                        icon={activeKey === faq.key ? faChevronUp : faChevronDown} 
                                        className="faq-icon" 
                                    />
                                </div>
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={faq.key}>
                            <Card.Body className="faq-body">{faq.answer}</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                ))}
            </Accordion>
            {visibleCount < faqs.length && (
                <div className="text-center mt-4">
                    <Button onClick={showMoreFAQs} variant="primary">Show More</Button>
                </div>
            )}
        </div>
    );
};

export default FAQ;
